export default {
  nav: {
    home: 'ホーム',
    products: '製品',
    solutions: 'ソリューション',
    pricing: '料金',
    mentor: 'メンター',
    zhuoxiaohui: '卓小会',
    about: '会社概要'
  },
  common: {
    loginRegister: 'ログイン/登録',
    getStarted: '始める',
    learnMore: '詳細を見る',
    contactUs: 'お問い合わせ',
    viewMore: 'もっと見る',
    submit: '送信',
    tryFree: '無料トライアル'
  },
  home: {
    hero: {
      title: 'Great Manager',
      subtitle: '企業の管理効率を向上させ、デジタルトランスフォーメーションを実現',
      cta: '始める'
    },
    features: {
      dataAnalysis: {
        title: 'リアルタイムデータ分析',
        desc: '大規模データを迅速に処理・分析し、リアルタイムで可視化レポートを生成して市場動向を把握'
      },
      automation: {
        title: 'インテリジェント自動化',
        desc: '反復的な作業フローを自動化し、効率を向上させ、人的ミスを減らし、より価値の高いタスクに集中'
      },
      prediction: {
        title: '予測分析',
        desc: '機械学習アルゴリズムを使用してビジネストレンドを予測し、戦略を事前に計画し、市場機会を獲得'
      }
    },
    cases: {
      title: '導入事例',
      subtitle: '様々な業界での成功事例から、デジタルトランスフォーメーションの実現方法をご紹介',
      viewMore: '事例をもっと見る'
    },
    stats: {
      clients: '企業クライアント',
      availability: 'サービス可用性',
      dataProcessed: 'データ処理量',
      support: '技術サポート'
    },
    cta: {
      title: 'デジタルトランスフォーメーションを始める準備はできていますか？',
      subtitle: '14日間の無料トライアルを開始し、Great Managerがもたらす変化を体験してください',
      tryFree: '無料トライアル',
      contactSales: '営業に相談'
    }
  },
  products: {
    hero: {
      title: '包括的なインテリジェントデータソリューション',
      subtitle: 'データ収集から分析洞察まで、自動化からインテリジェントな意思決定まで、エンドツーエンドの企業向けソリューションを提供'
    },
    features: {
      dataAnalysis: {
        title: 'データ分析プラットフォーム',
        desc: 'リアルタイムおよびバッチデータ処理をサポートする強力なデータ処理エンジンで、より速く、より正確な分析を実現'
      },
      automation: {
        title: 'インテリジェント自動化',
        desc: 'AIベースのワークフロー自動化ソリューションで、効率を向上させ、人的ミスを削減'
      },
      prediction: {
        title: '予測分析',
        desc: '機械学習アルゴリズムを使用して過去のデータを分析し、将来のトレンドを予測し、適切な意思決定をサポート'
      }
    },
    integration: {
      title: 'シームレスな統合',
      subtitle: '主要な企業向けソフトウェアやサービスとの迅速な統合をサポート'
    }
  },
  mentor: {
    hero: {
      title: 'プロフェッショナルメンターガイダンス',
      subtitle: 'マンツーマンコーチングで迅速な成長をサポート'
    },
    filters: {
      field: '専門分野',
      experience: '実務経験',
      all: 'すべて'
    },
    categories: {
      management: '経営コンサルティング',
      hr: '人事',
      marketing: 'マーケティング',
      finance: '財務管理',
      operation: '運営管理',
      startup: 'スタートアップ支援'
    },
    experienceYears: {
      five: '5年以上',
      ten: '10年以上',
      fifteen: '15年以上',
      twenty: '20年以上'
    },
    bookConsultation: '相談予約'
  }
  // ... その他のページの翻訳
} 