export default {
  nav: {
    home: 'Home',
    products: 'Products',
    solutions: 'Solutions',
    pricing: 'Pricing',
    about: 'About'
  },
  common: {
    loginRegister: 'Login/Register',
    getStarted: 'Get Started',
    learnMore: 'Learn More',
    contactUs: 'Contact Us',
    viewMore: 'View More',
    submit: 'Submit',
    tryFree: 'Try Free'
  },
  home: {
    hero: {
      title: 'DataSmart Analytics',
      subtitle: 'Drive Data Value with AI, Empower Enterprise Digital Transformation',
      cta: 'Get Started'
    },
    features: {
      dataAnalysis: {
        title: 'Real-time Analytics',
        desc: 'Powerful data processing engine supporting real-time analysis and visualization to help you gain business insights'
      },
      automation: {
        title: 'Intelligent Automation',
        desc: 'AI-driven data processing workflows, automating data cleansing, transformation, and analysis'
      },
      prediction: {
        title: 'Predictive Analytics',
        desc: 'Machine learning-based intelligent predictions to identify business opportunities and mitigate potential risks'
      }
    },
    cases: {
      title: 'Case Studies',
      subtitle: 'Success stories of digital transformation across industries, witnessing data-driven business growth',
      viewMore: 'View More Cases'
    },
    stats: {
      clients: 'Enterprise Clients',
      availability: 'Service Availability',
      dataProcessed: 'Data Processed',
      support: 'Technical Support'
    },
    cta: {
      title: 'Ready to Start Your Data Intelligence Journey?',
      subtitle: 'Start your 14-day free trial and experience the power of DataSmart Analytics',
      tryFree: 'Try Free',
      contactSales: 'Contact Sales'
    }
  },
  products: {
    hero: {
      title: 'Comprehensive Data Intelligence Solutions',
      subtitle: 'From data collection to insights, from automation to intelligent decisions, we provide end-to-end enterprise data services'
    },
    features: {
      dataAnalysis: {
        title: 'Data Analysis Platform',
        desc: 'Powerful data processing engine supporting real-time and batch processing for faster and more accurate analysis'
      },
      automation: {
        title: 'Intelligent Automation',
        desc: 'AI-based data processing automation solutions to improve efficiency and ensure data quality'
      },
      prediction: {
        title: 'Predictive Analytics',
        desc: 'Use machine learning algorithms to analyze historical data and predict future trends'
      }
    },
    integration: {
      title: 'Seamless Integration',
      subtitle: 'Quick integration with mainstream enterprise systems and data sources'
    }
  }
} 