export default {
  nav: {
    home: '首页',
    products: '产品',
    solutions: '解决方案',
    pricing: '定价',
    about: '关于我们'
  },
  common: {
    loginRegister: '登录/注册',
    getStarted: '开始使用',
    learnMore: '了解更多',
    contactUs: '联系我们',
    viewMore: '查看更多',
    submit: '提交',
    tryFree: '免费试用'
  },
  home: {
    hero: {
      title: '数智云析（即将推出）',
      subtitle: '用AI驱动数据价值，助力企业数字化转型',
      cta: '立即体验'
    },
    features: {
      dataAnalysis: {
        title: '实时数据分析',
        desc: '强大的数据处理引擎，支持实时分析和可视化，助您洞察业务趋势'
      },
      automation: {
        title: '智能自动化',
        desc: 'AI驱动的数据处理流程，自动化数据清洗、转换和分析，提升效率'
      },
      prediction: {
        title: '预测分析',
        desc: '基于机器学习的智能预测，提前发现业务机会，规避潜在风险'
      }
    },
    cases: {
      title: '客户案例',
      subtitle: '来自各行业的数字化转型成功案例，见证数据驱动业务增长',
      viewMore: '查看更多案例'
    },
    stats: {
      clients: '企业客户',
      availability: '服务可用性',
      dataProcessed: '数据处理量',
      support: '技术支持'
    },
    cta: {
      title: '准备好开启数据智能之旅了吗？',
      subtitle: '立即开始 14 天免费试用，体验数智云析带来的改变',
      tryFree: '免费试用',
      contactSales: '联系销售'
    }
  },
  products: {
    hero: {
      title: '全方位的数据智能解决方案',
      subtitle: '从数据采集到分析洞察，从自动化到智能决策，提供端到端的企业级数据服务'
    },
    features: {
      dataAnalysis: {
        title: '数据分析平台',
        desc: '强大的数据处理引擎，支持实时和批量数据处理，让您的数据分析更快、更准确'
      },
      automation: {
        title: '智能自动化',
        desc: '基于 AI 的数据处理自动化方案，提高效率，确保数据质量'
      },
      prediction: {
        title: '预测分析',
        desc: '利用机器学习算法，分析历史数据，预测未来趋势，助您做出明智决策'
      }
    },
    integration: {
      title: '无缝集成',
      subtitle: '支持与主流企业系统和数据源的快速集成'
    }
  }
} 