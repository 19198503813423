<template>
  <div class="relative">
    <!-- Hero Section -->
    <div class="relative">
      <div class="absolute inset-0">
        <img
          class="h-full w-full object-cover"
          :src="homeBanner"
          alt="Background"
        />
        <div class="absolute inset-0 bg-gray-500 mix-blend-multiply"></div>
      </div>
      
      <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
          {{ t('home.hero.title') }}
        </h1>
        <p class="mt-6 text-xl text-white max-w-3xl">
          {{ t('home.hero.subtitle') }}
        </p>
        <div class="mt-10">
          <button class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            {{ t('home.hero.cta') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Features Section -->
    <section class="py-20">
      <div class="container">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <div class="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-6">
              <svg class="w-6 h-6 text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-4">{{ $t('home.features.dataAnalysis.title') }}</h3>
            <p class="text-gray-600">
              {{ $t('home.features.dataAnalysis.desc') }}
            </p>
          </div>

          <div class="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <div class="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-6">
              <svg class="w-6 h-6 text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-4">{{ $t('home.features.automation.title') }}</h3>
            <p class="text-gray-600">
              {{ $t('home.features.automation.desc') }}
            </p>
          </div>

          <div class="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <div class="w-12 h-12 bg-primary-100 rounded-lg flex items-center justify-center mb-6">
              <svg class="w-6 h-6 text-primary-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-4">{{ $t('home.features.prediction.title') }}</h3>
            <p class="text-gray-600">
              {{ $t('home.features.prediction.desc') }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Case Studies Section -->
    <section class="py-20 bg-gray-50">
      <div class="container">
        <div class="text-center max-w-3xl mx-auto mb-16">
          <h2 class="text-3xl font-bold mb-4">{{ $t('home.cases.title') }}</h2>
          <p class="text-gray-600">
            {{ $t('home.cases.subtitle') }}
          </p>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <!-- 金融行业案例 -->
          <div class="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-lg transition-shadow">
            <router-link :to="{ name: 'case-detail', params: { id: 'finance' }}" class="block">
              <div class="relative">
                <div class="w-full h-48 bg-gradient-to-br from-blue-700 via-blue-600 to-blue-900"></div>
                <div class="absolute top-4 left-4">
                  <span class="px-3 py-1 bg-white/90 rounded-full text-sm font-medium text-primary-600">
                    金融行业
                  </span>
                </div>
              </div>
              <div class="p-6">
                <h3 class="text-xl font-bold mb-2">某大型银行智能风控系统</h3>
                <p class="text-gray-600 mb-4">
                  通过部署智能风控系统，成功将风险识别准确率提升40%，处理效率提高3倍，年节省运营成本超过1000万。
                </p>
                <div class="space-y-3 text-sm text-gray-600">
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    风险识别准确率提升40%
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    处理效率提高3倍
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    年节省成本1000+
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <!-- 零售行业案例 -->
          <div class="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-lg transition-shadow">
            <router-link :to="{ name: 'case-detail', params: { id: 'retail' }}" class="block">
              <div class="relative">
                <div class="w-full h-48 bg-gradient-to-br from-emerald-700 via-emerald-600 to-emerald-900"></div>
                <div class="absolute top-4 left-4">
                  <span class="px-3 py-1 bg-white/90 rounded-full text-sm font-medium text-primary-600">
                    零售行业
                  </span>
                </div>
              </div>
              <div class="p-6">
                <h3 class="text-xl font-bold mb-2">全渠道零售商智能运营</h3>
                <p class="text-gray-600 mb-4">
                  通过AI驱动的需求预测和库存优化系统，帮助客户实现精准营销和库存管理，显著提升运营效率。
                </p>
                <div class="space-y-3 text-sm text-gray-600">
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    销售转化率提升35%
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    库存周转率提升25%
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    营销ROI提升50%
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <!-- 制造业案例 -->
          <div class="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-lg transition-shadow">
            <router-link :to="{ name: 'case-detail', params: { id: 'manufacture' }}" class="block">
              <div class="relative">
                <div class="w-full h-48 bg-gradient-to-br from-violet-700 via-violet-600 to-violet-900"></div>
                <div class="absolute top-4 left-4">
                  <span class="px-3 py-1 bg-white/90 rounded-full text-sm font-medium text-primary-600">
                    制造业
                  </span>
                </div>
              </div>
              <div class="p-6">
                <h3 class="text-xl font-bold mb-2">智能工厂数字化转型</h3>
                <p class="text-gray-600 mb-4">
                  通过部署智能制造解决方案，实现生产全流程数字化监控和预测性维护，大幅提升生产效率。
                </p>
                <div class="space-y-3 text-sm text-gray-600">
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    设备利用率提升30%
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    维护成本降低40%
                  </div>
                  <div class="flex items-center">
                    <svg class="w-5 h-5 text-primary-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4" />
                    </svg>
                    生产效率提升45%
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <!-- View More Button -->
        <div class="text-center mt-12">
          <button class="px-8 py-3 border-2 border-primary-600 text-primary-600 rounded-lg hover:bg-primary-50 transition-colors">
            {{ $t('home.cases.viewMore') }}
          </button>
        </div>
      </div>
    </section>

    <!-- Stats Section -->
    <section class="py-20">
      <div class="container">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div class="text-center">
            <div class="text-4xl font-bold text-primary-600 mb-2">500+</div>
            <div class="text-gray-600">{{ $t('home.stats.clients') }}</div>
          </div>
          <div class="text-center">
            <div class="text-4xl font-bold text-primary-600 mb-2">99.9%</div>
            <div class="text-gray-600">{{ $t('home.stats.availability') }}</div>
          </div>
          <div class="text-center">
            <div class="text-4xl font-bold text-primary-600 mb-2">10PB+</div>
            <div class="text-gray-600">{{ $t('home.stats.dataProcessed') }}</div>
          </div>
          <div class="text-center">
            <div class="text-4xl font-bold text-primary-600 mb-2">24/7</div>
            <div class="text-gray-600">{{ $t('home.stats.support') }}</div>
          </div>
        </div>
      </div>
    </section>

    <!-- CTA Section -->
    <section class="py-20">
      <div class="container">
        <div class="bg-primary-600 rounded-2xl p-12 text-center text-white relative overflow-hidden">
          <div class="relative z-10">
            <h2 class="text-3xl md:text-4xl font-bold mb-6">
              {{ $t('home.cta.title') }}
            </h2>
            <p class="text-xl mb-8 text-primary-100">
              {{ $t('home.cta.subtitle') }}
            </p>
            <div class="flex flex-col sm:flex-row justify-center gap-4">
              <button class="px-8 py-4 bg-white text-primary-600 rounded-lg hover:bg-primary-50 transition-colors">
                {{ $t('home.cta.tryFree') }}
              </button>
              <button class="px-8 py-4 border border-white rounded-lg hover:bg-primary-700 transition-colors">
                {{ $t('home.cta.contactSales') }}
              </button>
            </div>
          </div>
          <!-- 背景装饰 -->
          <div class="absolute top-0 left-0 w-full h-full">
            <div class="absolute top-0 right-0 w-1/3 h-full bg-primary-500 opacity-50 transform rotate-12 translate-x-1/2"></div>
            <div class="absolute bottom-0 left-0 w-1/3 h-full bg-primary-700 opacity-50 transform -rotate-12 -translate-x-1/2"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { onMounted } from 'vue'
import homeBanner from '@/assets/images/home-banner.png'

const { t } = useI18n()

// 添加结构化数据
const structuredData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "智讯数科",
  "description": "专业的数据智能与分析解决方案提供商",
  "url": "https://你的网站域名",
  "logo": "https://你的网站域名/logo.png",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "88888888",
    "contactType": "customer service",
    "areaServed": "CN",
    "availableLanguage": ["Chinese"]
  }
}

onMounted(() => {
  // 添加结构化数据到页面
  const script = document.createElement('script')
  script.setAttribute('type', 'application/ld+json')
  script.textContent = JSON.stringify(structuredData)
  document.head.appendChild(script)
})
</script>