<template>
  <footer class="bg-gray-900 text-white py-16">
    <div class="container">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
        <!-- 公司信息 -->
        <div class="space-y-4">
          <div class="flex items-center space-x-2">
            <img src="../assets/logo.svg" alt="Antworers" class="h-8 w-auto brightness-0 invert" />
            <span class="text-xl font-semibold">智讯数科</span>
          </div>
          <p class="text-gray-400">
            为企业提供智能化数据分析和自动化解决方案
          </p>
          <div class="flex space-x-4">
            <a href="#" class="text-gray-400 hover:text-white">
              <span class="sr-only">微信</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
              </svg>
            </a>
          </div>
        </div>

        <!-- 产品 -->
        <div>
          <h3 class="text-lg font-semibold mb-4">产品</h3>
          <ul class="space-y-2">
            <li><a href="#" class="text-gray-400 hover:text-white">数据分析平台</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">智能自动化</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">预测分析</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">业务智能</a></li>
          </ul>
        </div>

        <!-- 资源 -->
        <div>
          <h3 class="text-lg font-semibold mb-4">资源</h3>
          <ul class="space-y-2">
            <li><a href="#" class="text-gray-400 hover:text-white">文档中心</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">API 参考</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">案例研究</a></li>
            <li><a href="#" class="text-gray-400 hover:text-white">博客</a></li>
          </ul>
        </div>

        <!-- 联系我们 -->
        <div>
          <h3 class="text-lg font-semibold mb-4">联系我们</h3>
          <ul class="space-y-2">
            <li class="text-gray-400">
              <span class="block">邮箱：</span>
              <a href="mailto:contact@antworers.com" class="hover:text-white">azurecz@yeah.net</a>
            </li>
            <li class="text-gray-400">
              <span class="block">电话：</span>
              <a href="tel:+1234567890" class="hover:text-white">88888888</a>
            </li>
            <li class="text-gray-400">
              <span class="block">地址：</span>
              <span>河南省郑州市</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-12 pt-8 border-t border-gray-800">
        <div class="flex flex-col md:flex-row justify-between items-center">
          <p class="text-gray-400 text-sm">
            © {{ new Date().getFullYear() }} 智讯数科. 保留所有权利
          </p>
          <p class="text-gray-400 text-sm">
            <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2024098375号</a>
          </p>
          <div class="flex space-x-6 mt-4 md:mt-0">
            <a href="#" class="text-gray-400 hover:text-white text-sm">隐私政策</a>
            <a href="#" class="text-gray-400 hover:text-white text-sm">服务条款</a>
            <a href="#" class="text-gray-400 hover:text-white text-sm">Cookie 政策</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template> 