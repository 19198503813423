import { createI18n } from 'vue-i18n'
import zh from '../locales/zh'
import en from '../locales/en'
import ja from '../locales/ja'

const i18n = createI18n({
  legacy: false, // 使用 Composition API
  locale: 'zh', // 默认语言
  fallbackLocale: 'en', // 备用语言
  messages: {
    zh,
    en,
    ja
  }
})

export default i18n 