<template>
  <header class="bg-white shadow">
    <nav class="container mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center h-16">
        <!-- Logo -->
        <div class="flex-shrink-0">
          <router-link to="/" class="text-xl font-bold text-gray-900">智讯数科</router-link>
        </div>

        <!-- 导航菜单 -->
        <div class="hidden sm:block">
          <div class="flex space-x-8">
            <router-link 
              v-for="item in menuItems" 
              :key="item.path"
              :to="item.path"
              class="px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200"
              :class="[
                'hover:text-primary-600',
                {
                  'text-gray-600': !isActive(item.path),
                  'text-primary-600 font-semibold border-b-2 border-primary-600': isActive(item.path)
                }
              ]"
            >
              {{ item.name }}
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { useRoute } from 'vue-router'

const route = useRoute()

const menuItems = [
  { name: '首页', path: '/' },
  { name: '产品', path: '/products' },
  { name: '解决方案', path: '/solutions' },
  { name: '定价', path: '/pricing' },
  { name: '关于我们', path: '/about' },
]

// 判断当前路由是否激活
const isActive = (path) => {
  if (path === '/') {
    return route.path === '/'
  }
  return route.path.startsWith(path)
}
</script>

<style scoped>
.router-link-active {
  @apply text-primary-600;
}

/* 添加过渡动画 */
.border-b-2 {
  transition: border-color 0.2s ease;
}
</style>