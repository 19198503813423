import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: '智讯数科官网 - 智能数据分析解决方案提供商',
        metaTags: [
          {
            name: 'description',
            content: '智讯数科提供企业级数据分析和智能化解决方案，助力企业数字化转型'
          },
          {
            name: 'keywords',
            content: '数据分析,人工智能,企业数字化,智能决策'
          }
        ]
      }
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import('../views/Products.vue'),
      meta: {
        title: '产品中心 - 智讯数科',
        metaTags: [
          {
            name: 'description',
            content: '智讯数科提供数据分析平台、智能自动化、预测分析等产品，满足企业多样化的数据需求'
          }
        ]
      }
    },
    {
      path: '/solutions',
      name: 'solutions',
      component: () => import('../views/Solutions.vue')
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: () => import('../views/Pricing.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/Contact.vue')
    },
    {
      path: '/case/:id',
      name: 'case-detail',
      component: () => import('../views/CaseDetail.vue')
    },
    {
      path: '/zhuoxiaohui',
      name: 'ZhuoXiaoHui',
      component: () => import('../views/ZhuoXiaoHui.vue')
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('../views/About.vue')
    },
    {
      path: '/mentor',
      name: 'mentor',
      component: () => import('../views/Mentor.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '智讯数科官网'
  
  let metaTags = document.querySelectorAll('[data-vue-router-controlled]')
  metaTags.forEach(tag => tag.parentNode.removeChild(tag))
  
  if(to.meta.metaTags) {
    to.meta.metaTags.forEach(tagDef => {
      const tag = document.createElement('meta')
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key])
      })
      tag.setAttribute('data-vue-router-controlled', '')
      document.head.appendChild(tag)
    })
  }
  
  next()
})

export default router 